<template>
  <div class="settings-page">
    <b-loading :active.sync="isLoading" :is-full-page="false"></b-loading>
    <app-header icon="cog" title="Configurações"></app-header>

    <section>
      <form v-if="!isLoading" @submit.prevent>
        <div class="container-cadastro">
          <div class="title_cadastro">FALE CONOSCO</div>
            <div class="row">
              <div class="col-7 col-md-7 col-sm-3">
                <div class="card cadastro">
                  <div class="card-body">
                    <div class="card-title-cadastro">Contato</div>
                    <app-input
                      v-for="(field, index) in parameters"
                      :key="field.id"
                      v-model="parameters[index].value"
                    ></app-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-7 col-md-7 col-sm-3 button-container d-flex justify-content-end">
                <b-button variant="light" @click="goBack()" type="is-light">Voltar</b-button>
                <b-button 
                  class="button-rounded remove-focus btn-success border-0 px-3"
                  variant="light" 
                  @click="onSubmit()" 
                >
                  Salvar
                </b-button>
              </div>
            </div>
        </div>
      </form>
    </section>
  </div>
</template>

<script>
import ParametersService from '@/services/parameters.service';

export default {
  data: () => ({
    isLoading: false,
    isSaving: false,
    parameters: [],
  }),
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    onSubmit() {
      this.isSaving = true;

      ParametersService.store(this.parameters)
        .then(() =>
          this.$buefy.snackbar.open('Configurações atualizadas com sucesso.')
        )
        .catch(() =>
          this.$buefy.snackbar.open('Erro ao atualizar configurações.')
        )
        .finally(() => (this.isSaving = false));
    },
    load() {
      this.isLoading = true;

      ParametersService.get()
        .then(({ data }) => (this.parameters = data))
        .catch(() =>
          this.$buefy.snackbar.open('Erro ao carregar configurações.')
        )
        .finally(() => (this.isLoading = false));
    },
  },
  mounted() {
    this.load();
  },
};
</script>
